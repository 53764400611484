import { all, fork } from 'redux-saga/effects';
import login from './loginSaga';
import employeeWatcher from './employeeSaga';
import adminWatcher from './adminSaga';
export default function* root() {
  yield all([
    employeeWatcher(),
    login(),
    adminWatcher()
  ]);
}