import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Actions } from '../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeQuestions } from '../redux/reducer/employeeReducer';
import { getLoginData } from '../redux/reducer/loginReducer';
import AppLoader from '../component/AppLoader';
import { toast } from 'react-toastify';
import moment from 'moment';
import Avatar from 'react-avatar';
import Alert from '../component/alert';
const Employee = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [questionList, setQuestionList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const userData = useSelector(getLoginData);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [yearFilter, setYearFilter] = useState(1);
    const [yearList, setYearList] = useState();
    const [logoutModal, setLogoutModal] = useState(false)
    const [conformDraftModel, setConformDraftModel] = useState(false)
    const [conformSubmitModel, setConformSubmitModel] = useState(false)

    // const submitStatus = questionList?.[0]?.Answer?.isDraft;

    function asyncQuestionList() {
        const callback = (res) => {
            setLoading(false);
            if (res?.response_code === 0) {
                setQuestionList(res?.response)
                setSubmitStatus(res?.response?.[0]?.Answer?.isDraft)
            } else {
                toast.error(res?.response_message);
            }
        }
        let params = {
            token: userData?.token,
            yearId: yearFilter
        }
        setLoading(true);
        dispatch(Actions.questionList(params, callback))
    }
    function asyncYearList() {
        const callback = (res) => {
            setLoading(false);
            if (res?.response_code !== 0) {
                toast.error(res?.response_message);
            } else {
                setYearList(res?.response)
            }
        }
        let params = {
            token: userData?.token
        }
        setLoading(true);
        dispatch(Actions.yearList(params, callback))
    }
    useEffect(() => {
        asyncQuestionList()
        asyncYearList()
    }, [yearFilter])

    const handleQuestionChange = (e, i) => {
        const { value, name } = e.target || {}
        const data = [...questionList]
        if (!data[i]["Answer"]) {
            data[i]["Answer"] = {
                comments: null
            }
        }
        data[i]['Answer'][name] = value
        setQuestionList(data);
    }

    const submitQuestion = (e, isDraft) => {
        e?.preventDefault();
        setConformDraftModel(false);
        setConformSubmitModel(false);
        const submitQuestionCallback = (response) => {
            setLoading(false);
            if (response?.response_code == 0) {
                toast.success(response?.response_message);
                asyncQuestionList();
            } else {
                toast.error(response?.response_message);
            }
        }
        const isCreate = questionList.some(q => !q?.Answer?.hasOwnProperty("id"))
        if (!isCreate) {
            let params = {
                "isDraft": isDraft ? "1" : "0",
                "answer": questionList?.filter(f => f?.Answer?.id !== null)?.map(q => ({
                    id: q?.Answer?.id,
                    questionId: q?.id,
                    comments: q?.Answer?.comments,
                    userRating: q?.Answer?.userRating
                }))
            }
            setLoading(true)
            dispatch(Actions.updateAnswers(params, submitQuestionCallback))
        } else {
            let params = {
                "isDraft": isDraft ? "1" : "0",
                "answer": questionList.map(q => ({
                    questionId: q?.id,
                    comments: q?.Answer?.comments,
                    userRating: q?.Answer?.userRating
                }))
            }
            setLoading(true)
            dispatch(Actions.submitAnswers(params, submitQuestionCallback))
        }
    }
    console.log("questionList.some(q => !q?.Answer.hasOwnPropert", questionList.some(q => !q?.Answer?.hasOwnProperty("id")));
    const handleChange = (e, i) => {
        const data = [...questionList]
        if (!data[i]["Answer"]) {
            data[i]["Answer"] = {
                userRating: null
            }
        }
        data[i]['Answer']['userRating'] = e.target.value
        setQuestionList(data)
    }
    const logout = () => {
        setLogoutModal(false)
        const callback = async (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                localStorage.clear()
                navigate('/')
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            username: userData?.empId,
        }
        setLoading(true)
        dispatch(Actions.logOutUser(params, callback))
    }
    const handleYearFilterChange = (e) => {
        setYearFilter(e.target.value);
    };
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className='d-flex h-100 overflow-hidden body-gradient'>
                <div className='container h-100 overflow-hidden'>
                    <div className='row h-100 overflow-hidden'>
                        <div className='col-12 h-100 overflow-hidden'>
                            <div className='d-flex flex-column h-100 gap-3'>
                                <div className='py-5 text-white d-flex justify-content-between align-items-center'>
                                    <div className='d-flex gap-3'>
                                        {/* <div className='text-dark' style={{ width: '64px', height: '64px', borderRadius: '50%', backgroundColor: '#fdfdfd', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '24px' }}>
                                        {userData?.user?.userName}
                                    </div> */}
                                        <Avatar name={userData?.user?.userName} round={true} color={'#fdfdfd'} fgColor='black' size='35px' />
                                        <div className='d-flex flex-column align-items-start gap-2'>
                                            <h4 className='m-0 employee-name-responsive'>{userData?.user?.userName}</h4>
                                            <p className='m-0 employee-name-responsive' >{userData?.user?.designation}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <span className="badge header-responsive" style={{ backgroundColor: submitStatus === 0 ? "#00D100" : '#ffa78f' }}>{submitStatus === 0 ? "Submitted" : "Yet to submit"}</span>
                                        {submitStatus === 0 && <p className='m-0 small header-responsive-date'>Last submited: {moment(questionList[0]?.Answer?.updatedAt).format("DD/MM/YYYY hh:mm:ss:A")} </p>}
                                        <div>|</div>
                                        <Link to={'#'} onClick={() => setLogoutModal(true)} className='text-decoration-none d-flex gap-2 text-white align-items-center'>
                                            <i className='far fa-sign-out'></i>
                                            Sign Out
                                        </Link>
                                    </div>


                                </div>
                                <div className='flex-grow-1 overflow-auto bg-white p-4'>
                                    <div className='d-flex flex-column gap-4'>
                                        <div className='d-flex flex-column gap-4'>
                                            <div className="row mb-4 justify-content-end">
                                                <div className="col-md-3">
                                                    <select
                                                        className="form-select"
                                                        value={yearFilter}
                                                        onChange={handleYearFilterChange}
                                                    >
                                                        <option value="All" hidden>All</option>
                                                        {
                                                            yearList?.length > 0 && yearList?.map((o) => {
                                                                return (
                                                                    <option key={o?.id} value={o?.id}>{o?.year}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                questionList && questionList.length > 0 ?
                                                    (questionList?.[0]?.Answer?.isDraft === 0 ?
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            Your response has been submitted!
                                                        </div>
                                                        :
                                                        <>
                                                            <div className='d-flex justify-content-between mb-4'>
                                                                <p className='m-0 small text-secondary'>Fill your self evaluation</p>
                                                                <p className='m-0 small text-secondary'>Rate yourself</p>
                                                            </div>
                                                            <div className='d-flex flex-column gap-4'>
                                                                {questionList?.map((q, i) => {
                                                                    return (
                                                                        <div className='d-flex flex-column gap-2' key={i}>
                                                                            <div className='d-flex gap-3 justify-content-between responsive'>
                                                                                <h6>{i + 1}. {q?.questionName}</h6>
                                                                                <div className='d-flex align-items-center gap-3 range-responsive'>
                                                                                    <label className='m-0 small text-secondary'>Score:</label>
                                                                                    <input type="range" className="form-range" name="userRating" value={q?.Answer?.userRating ?? 0} onChange={(e) => handleChange(e, i)} min={0} max={10} step={0.5} id="customRange1" style={{ width: '300px' }} />
                                                                                    <p className='m-0 ms-3'>{q?.Answer?.userRating ? q?.Answer?.userRating : 0}/10</p>
                                                                                </div>
                                                                            </div>
                                                                            <textarea className='form-control' name="comments" onChange={(e) => handleQuestionChange(e, i)} value={q?.Answer?.comments} rows={2}></textarea>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            <div className='d-flex justify-content-end gap-3 bg-white p-3'>
                                                                <button className='btn btn-light' onClick={(e) => setConformDraftModel(true)}>Save Draft</button>
                                                                <button className='btn bg-primary' onClick={(e) => setConformSubmitModel(true)}>Submit</button>
                                                            </div>
                                                        </>
                                                    )
                                                    : (
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            Your Questions not ready!
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {logoutModal && <Alert
                id="Alert"
                description={"Do you want to logout?"}
                proceed={"Yes"}
                cancel={"No"}
                onSuccess={() => logout()}
                onFailure={() => setLogoutModal(false)}
            />}
            {conformDraftModel && <Alert
                id="Alert"
                description={"Are you sure you want to save this form as a draft?"}
                proceed={"Yes"}
                cancel={"No"}
                onSuccess={(e) => submitQuestion(e, true)}
                onFailure={() => setConformDraftModel(false)}
            />}
            {conformSubmitModel && <Alert
                id="Alert"
                description={"Are you sure you want to submit this form?"}
                proceed={"Yes"}
                cancel={"No"}
                onSuccess={(e) => submitQuestion(e, false)}
                onFailure={() => setConformSubmitModel(false)}
            />}
        </React.Fragment>
    );
};

export default Employee;