import { Actions } from "../action";

const initialState = {
  isLoading: false,
  error: false,
  questionList: [],
  reviewSubmission: {},
  yearList: [],
  saveDraft: {}
};
const commonState = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};
const commonFailer = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};
const questionListSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    questionList: action.data,
  };
};

const submissionSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    reviewSubmission: action.data,
  };
};

const saveDraft = (state, action) => {
  return {
    ...state,
    isLoading: false,
    saveDraft: action.data,
  };
};

const yearListSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    yearList: action.data,
  };
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.QUESTION_LIST: {
      return commonState(state);
    }
    case Actions.QUESTION_LIST_SUCCESS: {
      return questionListSuccess(state, action);
    }
    case Actions.QUESTION_LIST_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.SUBMIT_ANSWER: {
      return commonState(state);
    }
    case Actions.SUBMIT_ANSWER_SUCCESS: {
      return submissionSuccess(state, action);
    }
    case Actions.SUBMIT_ANSWER_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.SAVE_DRAFT: {
      return commonState(state);
    }
    case Actions.SAVE_DRAFT_SUCCESS: {
      return saveDraft(state, action);
    }
    case Actions.SAVE_DRAFT_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.YEAR_LIST: {
      return commonState(state);
    }
    case Actions.YEAR_LIST_SUCCESS: {
      return yearListSuccess(state, action);
    }
    case Actions.YEAR_LIST_FAILURE: {
      return commonFailer(state, action);
    }
    default:
      return state;
  }
};

export const getEmployeeQuestions = (state) => state?.EMPLOYEE?.questionList;
export const getYearList = (state) => state?.EMPLOYEE?.yearList;
