import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux/action";
import { getLoginData } from "../redux/reducer/loginReducer";
import { isValidEmail } from "../utils/Validation";
import MESSAGE, { IMG } from "../constant/String";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(getLoginData);
    const [formDetails, setFormDetails] = useState({ empId: "", password: "", captcha: "", captcha_id: "" });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({ password: "", empId: "", captcha: "" });
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (userData?.access_token) {
            if (userData?.user?.roleId === 1) {
                navigate('/admin')
            }
            if (userData?.user?.roleId === 2) {
                navigate('/employee')
            }
        }
    }, []);

    const handleEmployeeIdChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, empId: MESSAGE?.PLEASE_ENTER_THE_EMPLOYEE_ID });
        } else {
            setError({ ...error, empId: '' });
        }
        setFormDetails({ ...formDetails, empId: event.target.value });
    };

    const handlePasswordChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, password: MESSAGE?.PLEASE_ENTER_THE_PASSWORD });
        } else {
            setError({ ...error, password: '' });
        }
        setFormDetails({ ...formDetails, password: event.target.value });
    };

    const OnSubmit = (e) => {
        e.preventDefault();
        const { empId, password } = formDetails;
        const params = {
            grant_type: "password",
            empId,
            password,
            scope: "admin"
        }
        const callback = (response) => {
            setLoading(false);
            if (response?.response_code !== 0) {
                toast.error(response?.response_message);
            } else {
                toast.success(response?.response_message);
                if (response?.response?.user?.roleId == 1) {
                    if (response?.response?.user?.isPasswordVerify) {
                        navigate("/admin")
                    } else {
                        navigate("/resetpassword", { state: { value: 1 } })
                    }
                } else {
                    if (response?.response?.user?.isPasswordVerify) {
                        navigate('/employee')
                    } else {
                        navigate("/resetpassword", { state: { value: 1 } })
                    }
                }
            }
        };
        if (formDetails?.empId === "") {
            setError({ ...error, empId: MESSAGE?.PLEASE_ENTER_THE_EMPLOYEE_ID });
        } else if (formDetails?.password === "") {
            setError({ ...error, password: MESSAGE?.PLEASE_ENTER_THE_PASSWORD });
        } else {
            setLoading(true);
            dispatch(Actions.loginUser(params, callback));
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div style={{ backgroundColor: '#FFF1D8', height: '100%', display: 'flex', alignItems: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mx-auto">
                            <div className="d-flex flex-column">
                                <div className="bg-primary p-5 text-center">
                                    <img src={IMG.LOGO} className="w-50" />
                                    <h5 className="mb-0 mt-3 text-white">PMS Portal</h5>
                                </div>
                            </div>
                            <div className="bg-white p-4">
                                <h3>Login</h3>
                                <div className="mb-3 mt-4">
                                    <label htmlFor="name" className="form-label">Employee Id</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="EmpId"
                                        placeholder="Enter employee id"
                                        onChange={(e) => handleEmployeeIdChange(e)} />
                                    {error?.empId && <p className="text-danger mt-2">{error?.empId}</p>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            value={formDetails?.password}
                                            onChange={(e) => handlePasswordChange(e)}
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter password" />
                                        <button onClick={togglePasswordVisibility} className="btn btn-outline-secondary" type="button" id="password">
                                            <i className={`${!showPassword ? 'far fa-eye-slash' : 'far fa-eye'}`}></i>
                                        </button>
                                    </div>
                                    {error?.password && <p className="text-danger mt-2">{error?.password}</p>}
                                </div>
                                <div className="text-end">
                                    <Link to={'/forgetpassword'}>Forgot Password?</Link>
                                </div>
                                <button className="btn btn-primary mt-3 w-100 border-0" type="button" onClick={(e) => OnSubmit(e)}>
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;