import { Actions } from '../action';

const initialState = {
    isLoading: false,
    token: null,
    refreshToken: null,
    client_credentials: null,
    public_token: null,
    captcha_image: null,
    captcha_id: null,
    expire_time: null,
    user: null,
    userEmailId: null,
    error: false,
}
const commonState = (state) => {
    return {
        ...state,
        isLoading: true,
    };
}
const commonFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        error: action.error,
    };
}
const commonSuccessForCreateUpdate = (state) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
}

const commonLogOutUpdate = (state) => {
    console.log("----LOGOUT---SUCCESS---");
    return {
        ...state,
        isLoading: false,
        user: null,
        error: false
    };
}
const commonLoginSuccess = (state, action) => {
    return {
        ...state,
        isLoading: false,
        token: action?.data?.access_token,
        refreshToken: action?.data?.refresh_token,
        user: action?.data,
        error: false
    };
}
const commonLoginFailer = (state, action) => {
    return {
        ...state,
        isLoading: false,
        token: null,
        refreshToken: null,
        user: null,
        error: action.error,
    };
}
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.LOGIN_USER: {
            return commonState(state);
        }
        case Actions.LOGIN_USER_SUCCESS: {
            return commonLoginSuccess(state, action);
        }
        case Actions.LOGIN_USER_FAILURE: {
            return commonLoginFailer(state, action)
        }
        case Actions.CLIENT_CREDENTIALS: {
            return commonState(state);
        }
        case Actions.CLIENT_CREDENTIALS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                client_credentials: action?.data?.public_token,
                expire_time: action?.data?.expire_time,
                error: false
            };
        }
        case Actions.CLIENT_CREDENTIALS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                client_credentials: null,
                expire_time: null,
                error: action.error,
            };
        }
        case Actions.CAPTCHA: {
            return commonState(state);
        }
        case Actions.CAPTCHA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                captcha_id: action?.data?.captcha_id,
                captcha_image: action?.data?.captcha_image,
                error: false
            };
        }
        case Actions.CAPTCHA_FAILURE: {
            return {
                ...state,
                isLoading: false,
                captcha_id: null,
                captcha_image: null,
                error: action.error,
            };
        }
        case Actions.PUBLIC_TOKEN_SUCCESS: {
            return {
                ...state,
                public_token: action.data
            }
        }
        case Actions.REFRESH_TOKEN: {
            return commonState(state);
        }
        case Actions.REFRESH_TOKEN_SUCCESS: {
            return commonLoginSuccess(state, action);
        }
        case Actions.REFRESH_TOKEN_FAILURE: {
            return commonLoginFailer(state, action)
        }
        case Actions.LOGOUT_USER: {
            return commonState(state);
        }
        case Actions.LOGOUT_USER_SUCCESS: {
            return commonLogOutUpdate(state);
        }
        case Actions.LOGOUT_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.FORGET_PASSWORD_USER: {
            return commonState(state);
        }
        case Actions.FORGET_PASSWORD_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.FORGET_PASSWORD_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.RESET_PASSWORD_USER: {
            return commonState(state);
        }
        case Actions.RESET_PASSWORD_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.RESET_PASSWORD_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.VERIFY_OTP_USER: {
            return commonState(state);
        }
        case Actions.VERIFY_OTP_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.VERIFY_OTP_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.CHANGE_PASSWORD_USER: {
            return commonState(state);
        }
        case Actions.CHANGE_PASSWORD_USER_SUCCESS: {
            return commonSuccessForCreateUpdate(state);
        }
        case Actions.CHANGE_PASSWORD_USER_FAILURE: {
            return commonFailer(state, action);
        }
        case Actions.LOGIN_CHECK_DATA: {
            return {
                ...state,
                isLoading: false,
                userEmailId: action?.data,
                error: false
            };
        }
        case Actions.LOGOUT_USER_FOR_EXPIRE_TOKEN: {
            return commonLogOutUpdate(state);
        }
        default:
            return state;
    }
};

export const getLoginData = (state) => state?.USER?.user;
export const userEmailId = (state) => state?.USER?.userEmailId;

