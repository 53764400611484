import LOGO from '../asset/img/logo.png';

const MESSAGE = {
    LOGIN: "Login",
    PLEASE_ENTER_THE_EMPLOYEE_ID: "Please enter the Employee Id",
    PLEASE_ENTER_THE_OTP: "Please enter the OTP",
    PLEASE_ENTER_VALID_OTP: "Please enter valid OTP",
    PLEASE_ENTER_THE_NEW_PASSWORD: "Please enter the new password",
    PLEASE_ENTER_THE_CONFIRM_PASSWORD: "Please enter the confirm password",
    ENTERED_PASSWORDS_ARE_NOT_SAME: "Entered passwords are not same",
    PLEASE_ENTER_THE_PASSWORD: "Please enter the password",
    ITEM_COUNT_PER_PAGE: 5
};
export const IMG = {
    LOGO
};

export const PAGE = {
    HOME: '/',
    FORGET_PASSWORD: '/forgetpassword',
    CHANGE_PASSWORD: '/changePassword',
    RESET_PASSWORD: '/resetPassword',
    EMPLOYEE: '/employee',
    ADMIN: '/admin'
};

export default MESSAGE;