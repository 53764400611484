const protocol = window.location.protocol || 'http:';
const hostname = window.location.hostname || 'localhost';
let port = window.location.port;

if (!port) {
    port = protocol === 'http:' ? 80 : protocol === 'https:' ? 443 : ''
}
let API_BASE_URL = 'http://localhost:8081/api/'
let API_AUTH_URL = 'http://localhost:8081/api/oauth'
let SOCKET_IO_PATH = ""
let SOCKET_URL = ""
if (hostname === '139.5.190.16') {
    API_BASE_URL = 'http://139.5.190.16:90/api/'
    API_AUTH_URL = 'http://139.5.190.16:90/api/oauth'
    SOCKET_IO_PATH = ""
    SOCKET_URL = ""
} else if (hostname === 'pms.proglint.com') {
    API_BASE_URL = 'https://pms-api.proglint.com/api/'
    API_AUTH_URL = 'https://pms-api.proglint.com/api/oauth'
    SOCKET_IO_PATH = ""
    SOCKET_URL = ""
}
const END_POINTS = {
    API_BASE_URL: API_BASE_URL,
    API_AUTH_URL: API_AUTH_URL,
    SOCKET_IO_PATH: SOCKET_IO_PATH,
    SOCKET_URL: SOCKET_URL,
    LOGIN: '/login',
    YEAR_LIST: '/year/list',
    EMPLOYEE_QUESTION_LIST: '/question/emp/list',
    ADMIN_EMPLOYEE_QUESTION_LIST: '/question',
    EMPLOYEE_REVIEW_SUBMISSION: 'answer/emp/submit',
    EMPLOYEE_REVIEW_UPDATE: 'answer/emp/update',
    SAVE_DRAFT: '/save',
    ADMIN_EMPLOYEE_REVIEW_SUBMISSION: '/review/add',
    ADMIN_EMPLOYEE_REVIEW_UPDATE: '/review/update',
    EMPLOYEE_LIST: '/users/emp/list',
    EMPLOYEE_DETAILS: '/users/emp/details',
    CLIENT_CREDENTIAL_TOKEN: '/oauth/token',
    CAPTCHA: '/create_captcha',
    REFRESH_TOKEN: '/refresh_token',
    LOGOUT: '/logout',
    FORGET_PASSWORD: '/auth/forget_password',
    RESET_PASSWORD: '/auth/reset_password',
    VERIFY_OTP: '/auth/verify_otp',
    CHANGE_PASSWORD: '/auth/change_password',

    //DASHBOARD
    GET_JOURNEY: '/journeyDashboard',
    GET_THEFT_ANALYSIS: '/theftAnalysisDashboard',
    GET_HIGH_VALUE_PRODUCT: '/highValueProductDashboard',
    GET_HIGH_VOLUME_PRODUCT: '/highVolumeProductDashboard',
    GET_TIME_ANALYSIS: '/timeAnalysisDashboard',
    GET_TOTAL_SAVED_PRODUCT: '/totalSaveProductDashboard',
    GET_TOTAL_SAVED_PRICE: '/totalSavePriceDashboard',
    GET_PREVENTED_PRODUCT: '/preventedProductAnalytic',

}

export default END_POINTS