import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { doGet, doPost } from '../../service';
import { ADMIN_QUESTION_LIST, EMPLOYEE_DETAILS, EMPLOYEE_LIST, SUBMIT_REVIEW, UPDATE_REVIEW } from '../action/adminAction';

export function* adminQuestionList({ params, callback }) {
  try {
    const response = yield doGet(END_POINTS.ADMIN_EMPLOYEE_QUESTION_LIST, params)
    yield put(Actions.adminQuestionListSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.adminQuestionListFailure(error))
  }
}

export function* adminReviewSubmission({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.ADMIN_EMPLOYEE_REVIEW_SUBMISSION, params)
    yield put(Actions.submitReviewSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.submitReviewFailure(error))
  }
}

export function* adminReviewUpdate({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.ADMIN_EMPLOYEE_REVIEW_UPDATE, params)
    yield put(Actions.updateReviewSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.updateReviewFailure(error))
  }
}

export function* employeeDetails({ params, callback }) {
  try {
    const response = yield doGet(END_POINTS.EMPLOYEE_DETAILS, params)
    yield put(Actions.employeeDetailsSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.employeeDetailsFailure(error))
  }
}

export function* employeeList({ params, callback }) {
  try {
    const response = yield doGet(END_POINTS.EMPLOYEE_LIST, params)
    yield put(Actions.employeeListSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.employeeListFailure(error))
  }
}

export default function* adminWatcher() {
  yield all([
    takeLatest(ADMIN_QUESTION_LIST, adminQuestionList),
    takeLatest(SUBMIT_REVIEW, adminReviewSubmission),
    takeLatest(UPDATE_REVIEW, adminReviewUpdate),
    takeLatest(EMPLOYEE_LIST, employeeList),
    takeLatest(EMPLOYEE_DETAILS, employeeDetails),
  ]);
}
