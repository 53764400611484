import { doAction, doResponseAction } from ".";

const QUESTION_LIST = 'QUESTION_LIST';
const QUESTION_LIST_SUCCESS = 'QUESTION_LIST_SUCCESS';
const QUESTION_LIST_FAILURE = 'QUESTION_LIST_FAILURE';

const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
const SUBMIT_ANSWER_SUCCESS = 'SUBMIT_ANSWER_SUCCESS';
const SUBMIT_ANSWER_FAILURE = 'SUBMIT_ANSWER_FAILURE';

const UPDATE_ANSWER = 'UPDATE_ANSWER';
const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
const UPDATE_ANSWER_FAILURE = 'UPDATE_ANSWER_FAILURE';

const SAVE_DRAFT = 'SAVE_DRAFT';
const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS';
const SAVE_DRAFT_FAILURE = 'SAVE_DRAFT_FAILURE';

const YEAR_LIST = 'YEAR_LIST';
const YEAR_LIST_SUCCESS = 'YEAR_LIST_SUCCESS';
const YEAR_LIST_FAILURE = 'YEAR_LIST_FAILURE';

export {
    QUESTION_LIST,
    QUESTION_LIST_FAILURE,
    QUESTION_LIST_SUCCESS,

    SAVE_DRAFT,
    SAVE_DRAFT_FAILURE,
    SAVE_DRAFT_SUCCESS,

    SUBMIT_ANSWER,
    SUBMIT_ANSWER_FAILURE,
    SUBMIT_ANSWER_SUCCESS,

    UPDATE_ANSWER,
    UPDATE_ANSWER_FAILURE,
    UPDATE_ANSWER_SUCCESS,

    YEAR_LIST,
    YEAR_LIST_FAILURE,
    YEAR_LIST_SUCCESS,

}

// QUESTION_LIST
export const questionList = (params, callback) => doAction(QUESTION_LIST, params, callback);
export const questionListSuccess = (data) => doResponseAction(QUESTION_LIST_SUCCESS, data);
export const questionListFailure = (data) => doResponseAction(QUESTION_LIST_FAILURE, data);

// QUESTION_SUBMIT
export const submitAnswers = (params, callback) => doAction(SUBMIT_ANSWER, params, callback);
export const submitAnswersSuccess = (data) => doResponseAction(SUBMIT_ANSWER_SUCCESS, data);
export const submitAnswersFailure = (data) => doResponseAction(SUBMIT_ANSWER_FAILURE, data);

// QUESTION_UPDATE
export const updateAnswers = (params, callback) => doAction(UPDATE_ANSWER, params, callback);
export const updateAnswersSuccess = (data) => doResponseAction(UPDATE_ANSWER_SUCCESS, data);
export const updateAnswersFailure = (data) => doResponseAction(UPDATE_ANSWER_FAILURE, data);

// SAVE_DRAFT
export const saveDraft = (params, callback) => doAction(SAVE_DRAFT, params, callback);
export const saveDraftSuccess = (data) => doResponseAction(SAVE_DRAFT_SUCCESS, data);
export const saveDraftFailure = (data) => doResponseAction(SAVE_DRAFT_FAILURE, data);

// YEAR_LIST
export const yearList = (params, callback) => doAction(YEAR_LIST, params, callback);
export const yearListSuccess = (data) => doResponseAction(YEAR_LIST_SUCCESS, data);
export const yearListFailure = (data) => doResponseAction(YEAR_LIST_FAILURE, data);

