import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getLoginData } from '../redux/reducer/loginReducer';
import { Actions } from '../redux/action';
import AppLoader from '../component/AppLoader';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { PaginationComponent } from '../component/Pagination';
import Alert from '../component/alert';
import MESSAGE from '../constant/String';

const EmployeeList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(getLoginData);
    const [employeeList, setEmployeeList] = useState();
    const [yearList, setYearList] = useState();
    const [currentIndex, setCurrentIndex] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState()
    const [statusFilter, setStatusFilter] = useState('All');
    const [yearFilter, setYearFilter] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [selectSearch, setSelectedSearch] = useState()
    const [logoutModal, setLogoutModal] = useState(false)
    function asyncEmployeList() {
        const callback = (res) => {
            setLoading(false);
            if (res?.response_code !== 0) {
                toast.error(res?.response_message);
            } else {
                setEmployeeList(res?.response?.result)
                setTotalItems(res?.response?.totalItems)
            }
        }
        let params = {
            token: userData?.token,
            yearId: yearFilter === 'All' ? "" : yearFilter,
            search: selectSearch,
            pageNumber: pageNumber - 1,
            limit: MESSAGE?.ITEM_COUNT_PER_PAGE
        }
        setLoading(true);
        dispatch(Actions.employeeList(params, callback))
    }
    function asyncYearList() {
        const callback = (res) => {
            setLoading(false);
            if (res?.response_code !== 0) {
                toast.error(res?.response_message);
            } else {
                setYearList(res?.response)
            }
        }
        let params = {
            token: userData?.token
        }
        setLoading(true);
        dispatch(Actions.yearList(params, callback))
    }
    useEffect(() => {
        asyncEmployeList()
        asyncYearList()
    }, [yearFilter, selectSearch, pageNumber])

    useLayoutEffect(() => {
        const callback = (res) => {
            // setLoading(false);
            if (res?.response_code !== 0) {
                toast.error(res?.response_message);
            } else {
                setEmployeeDetails(res?.response);
            }
        }
        let params = {
            yearId: yearFilter,
            userId: currentIndex,
        }
        // setLoading(true);
        if (currentIndex) {
            dispatch(Actions.employeeDetails(params, callback))
        }
    }, [isModalOpen])
    const handleOpenModal = (index) => {
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setCurrentIndex(null);
        setIsModalOpen(false);
        asyncEmployeList();
    };
    const logout = () => {
        setLogoutModal(false)
        const callback = async (response) => {
            setLoading(false)
            if (response?.response_code === 0) {
                localStorage.clear()
                navigate('/')
            } else {
                toast.error(response?.response_message)
            }
        }
        let params = {
            username: userData?.empId,
        }
        setLoading(true)
        dispatch(Actions.logOutUser(params, callback))
    }
    const [reviews, setReviews] = useState([]);
    useEffect(() => {
        if (employeeDetails?.Answers) {
            const initialReviews = employeeDetails.Answers.map(e => ({
                answerId: e?.id,
                comments: e?.Review?.comments || '',
                userId: e?.userId,
                review1: e?.Review?.review1 || 0,
                review2: e?.Review?.review2 || 0,
                review3: e?.Review?.review3 || 0,
                review4: e?.Review?.review4 || 0,
                review5: e?.Review?.review5 || 0,
                id: e?.Review?.id
            }));
            setReviews(initialReviews);
        }
    }, [employeeDetails]);

    const handleRatingChange = (index, reviewer, value, element) => {
        const newReviews = [...reviews];
        newReviews[index]["answerId"] = element?.id
        newReviews[index][`review${reviewer}`] = value;
        setReviews(newReviews);
    };

    const handleCommentsChange = (index, value, element) => {
        const newReviews = [...reviews];
        newReviews[index]["answerId"] = element?.id
        newReviews[index].comments = value;
        setReviews(newReviews);
    };

    const saveReviews = () => {
        const submitReviewCallback = (response) => {
            setLoading(false);
            if (response?.response_code == 0) {
                toast.success(response?.response_message);
                handleCloseModal();
            } else {
                toast.error(response?.response_message);
            }
        }
        setLoading(true)
        let params = {
            review: reviews
        }
        if (reviews?.[0]?.id) {
            dispatch(Actions.updateReview(params, submitReviewCallback))
        } else {
            dispatch(Actions.submitReview(params, submitReviewCallback))
        }
    };
    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };
    const handleYearFilterChange = (e) => {
        setYearFilter(e.target.value);
    };

    const onSearchChange = (selected) => {
        setPageNumber(1)
        setSelectedSearch(selected?.target?.value)
    }
    const onChangeHandle = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const clearSearch = () => {
        setSelectedSearch('');
    };
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div className='d-flex h-100 overflow-hidden body-gradient'>
                <div className='container h-100 overflow-hidden'>
                    <div className='row h-100 overflow-hidden'>
                        <div className='col-12 h-100 overflow-hidden'>
                            <div className='d-flex flex-column h-100 gap-3'>
                                <div className='py-3 text-white d-flex justify-content-between align-items-center'>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <Avatar name={userData?.user?.userName} round={true} color={'#fdfdfd'} fgColor='black' size='35px' />
                                        <div className='d-flex flex-column align-items-start gap-2'>
                                            <h4 className='m-0 employee-name-responsive'>{userData?.user?.userName}</h4>
                                            <p className='m-0 employee-name-responsive'>{userData?.user?.designation}</p>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <div>|</div>
                                        <Link to={'#'} onClick={() => setLogoutModal(true)} className='text-decoration-none d-flex gap-2 text-white align-items-center'>
                                            <i className='far fa-sign-out'></i>
                                            Sign Out
                                        </Link>
                                    </div>
                                </div>
                                <div className='flex-grow-1 overflow-y-auto bg-white p-4'>
                                    <div className='d-flex justify-content-between mb-4'>
                                        <p className='m-0 small text-secondary'>Employee List</p>
                                    </div>
                                    <div className='d-flex flex-column gap-4'>
                                        <div className='d-flex flex-column'>
                                            <div className="row mb-4">
                                                <div className="col-md-9 d-flex admin-responsive">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search by ID, Name, or Email"
                                                        value={selectSearch}
                                                        onChange={onSearchChange}
                                                    />
                                                    {selectSearch && (
                                                        <i className='bi bi-x search-close-icon' onClick={clearSearch}></i>
                                                    )}
                                                </div>
                                                {/* <div className="col-md-3">
                                                    <select
                                                        className="form-select"
                                                        value={statusFilter}
                                                        onChange={handleStatusFilterChange}
                                                    >
                                                        <option value="All">All</option>
                                                        <option value="Submitted">Submitted</option>
                                                        <option value="Yet to submit">Yet to submit</option>
                                                    </select>
                                                </div> */}
                                                <div className="col-md-3">
                                                    <select
                                                        className="form-select"
                                                        value={yearFilter}
                                                        onChange={handleYearFilterChange}
                                                    >
                                                        <option value="All" hidden>All</option>
                                                        {
                                                            yearList?.length > 0 && yearList?.map((o) => {
                                                                return (
                                                                    <option key={o?.id} value={o?.id}>{o?.year}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-striped table-bordered">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Emp ID</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>User Score</th>
                                                            <th>Reviewer Score</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {employeeList?.length > 0 ? employeeList?.map((e, i) => {
                                                            let reviewSubmitStatus = e?.Answers?.[0]?.Review?.id;
                                                            let userScore = 0
                                                            let questionWeight = 0
                                                            let review = 0
                                                            e?.Answers?.forEach((ele, index) => {
                                                                userScore += ele?.userRating
                                                                questionWeight += ele?.Question?.weightage
                                                                review += (ele?.Review?.review1 + ele?.Review?.review2 + ele?.Review?.review3 + ele?.Review?.review4 + ele?.Review?.review5)
                                                            })
                                                            let finalUserScore = (userScore / questionWeight) * 100
                                                            let finalReviewerScore = (review / (50 * e?.Answers?.length)) * 100
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{((pageNumber - 1) * MESSAGE?.ITEM_COUNT_PER_PAGE) + (i + 1)}</td>
                                                                    <td>{e?.empId}</td>
                                                                    <td>{e?.userName}</td>
                                                                    <td>{e?.email}</td>
                                                                    <td>{(finalUserScore || finalUserScore == 0) ? ((finalUserScore).toFixed(2) + "%") : "-"}</td>
                                                                    <td>{(finalReviewerScore || finalReviewerScore == 0) ? ((finalReviewerScore).toFixed(2) + "%") : "-"}</td>
                                                                    <td>
                                                                        <span className="badge" style={{ backgroundColor: reviewSubmitStatus ? "#00D100" : '#ffa78f' }}>
                                                                            {reviewSubmitStatus ? "Submitted" : "Yet to submit"}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <Link to="#" onClick={() => handleOpenModal(e?.id)}>View</Link>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }) : (
                                                            <tr>
                                                                <td colSpan="8" className="text-center">No employees found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {Array.isArray(employeeList) && employeeList?.length > 0 &&
                                                <div className="d-flex justify-content-between align-items-center mt-4">
                                                    <PaginationComponent onChangeHandle={onChangeHandle} pageCount={totalItems} pageNumber={pageNumber} setpage={setPageNumber} />
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isModalOpen && <div className="modal fade show" id="reviewercomments" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 text-secondary" id="exampleModalLabel">Give your feedbacks</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => handleCloseModal()}></button>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex flex-column gap-4'>
                                    {
                                        employeeDetails?.Answers?.length > 0 && employeeDetails?.Answers?.map((element, i) => {
                                            return (
                                                <div className='d-flex flex-column gap-2 reviewer-section pt-3 pb-4' key={i}>
                                                    <div className='d-flex flex-column gap-3'>
                                                        <h6 className='m-0'>{i + 1}. {element?.Question?.questionName}</h6>
                                                        <p className='text-uppercase text-secondary m-0 pt-2'><b>Employee Feedback:</b></p>
                                                        <div className='d-md-flex gap-3'>
                                                            <div className='d-flex gap-2'>
                                                                <p className='m-0'><b>Self Rating:</b></p>
                                                                <p>{element?.userRating ? element?.userRating : 0}/10</p>
                                                            </div>
                                                            {/* <div className='d-flex gap-2'>
                                                                <p className='m-0'><b>Score:</b></p>
                                                                <p className='text-width'>{element?.employeeScore}</p>
                                                            </div> */}
                                                            <div className='d-flex gap-2'>
                                                                <p className='m-0'><b>Comments:</b></p>
                                                                <p className='text-width'>{element?.comments ? element?.comments : "-"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className='text-uppercase text-secondary m-0 pt-2'><b>Your Feedback:</b></p>
                                                        {/* <Link data-bs-target="#viewcomments" data-bs-toggle="modal">View all comments</Link> */}
                                                    </div>
                                                    <div className='row review-align'>
                                                        {[1, 2, 3, 4, 5]?.map(reviewer => (
                                                            <div className='reviewer-comment' key={reviewer}>
                                                                <div className='d-flex flex-column gap-2'>
                                                                    <p className='m-0'><b>Reviewer {reviewer}</b></p>
                                                                    <div className='d-flex gap-3 justify-content-between admin-range-responsive'>
                                                                        <h6 className='h6-score-responsive'>Score:</h6>
                                                                        <div className='d-flex align-items-center gap-3'>
                                                                            <input
                                                                                type="range"
                                                                                className="form-range"
                                                                                min="0"
                                                                                max="10"
                                                                                step="0.5"
                                                                                value={reviews?.[i]?.[`review${reviewer}`]}
                                                                                onChange={(e) => handleRatingChange(i, reviewer, parseFloat(e.target.value), element)}
                                                                                style={{ width: '200px' }}
                                                                            />

                                                                            <p className='m-0 p-review-responsive'>{reviews?.[i]?.[`review${reviewer}`]}/10</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='reviewer-comment'>
                                                            <div className='d-flex flex-column gap-2'>
                                                                <p className='m-0'><b>Comments</b></p>
                                                                <textarea
                                                                    className='form-control'
                                                                    rows={2}
                                                                    value={reviews[i]?.comments}
                                                                    onChange={(e) => handleCommentsChange(i, e.target.value, element)}
                                                                    style={{ width: '100%' }}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='d-flex justify-content-end gap-3 bg-white p-3'>
                                <button className='btn bg-primary' data-bs-target="#reviewercomments" onClick={() => saveReviews()}>{reviews?.[0]?.id ? "Update" : "Submit"}</button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="modal fade" id="viewcomments" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">All Reviewer Comments</h1>
                            </div>
                            <div className="modal-body">
                                <div className='d-flex gap-3'>
                                    <p><b>Reviewer 1:</b></p>
                                    <p>Yes, I agree with this.</p>
                                </div>
                                <div className='d-flex gap-3'>
                                    <p><b>Reviewer 2:</b></p>
                                    <p>Yes, I agree with this.</p>
                                </div>
                                <div className='d-flex gap-3'>
                                    <p><b>Reviewer 3:</b></p>
                                    <p>Yes, I agree with this.</p>
                                </div>
                                <div className='d-flex gap-3'>
                                    <p><b>Reviewer 4:</b></p>
                                    <p>Yes, I agree with this.</p>
                                </div>
                                <div className='d-flex gap-3'>
                                    <p><b>Reviewer 5:</b></p>
                                    <p>Yes, I agree with this.</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className='btn bg-primary' data-bs-target="#reviewercomments" data-bs-toggle="modal">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {logoutModal && <Alert
                id="Alert"
                description={"Do you want to logout?"}
                proceed={"Yes"}
                cancel={"No"}
                onSuccess={() => logout()}
                onFailure={() => setLogoutModal(false)}
            />}
        </React.Fragment>
    );
};

export default EmployeeList;