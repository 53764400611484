import { Actions } from "../action";

const initialState = {
  isLoading: false,
  error: false,
  questionList: [],
  employeeList: [],
  adminReviewSubmission: null,
  adminReviewUpdate: null,
  employeeDetails: {},
};
const commonState = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};
const commonFailer = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};
const adminQuestionListSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    questionList: action.data,
  };
};

const employeeList = (state, action) => {
  return {
    ...state,
    isLoading: false,
    employeeList: action.data,
  };
};

const employeeDetails = (state, action) => {
  return {
    ...state,
    isLoading: false,
    employeeDetails: action.data,
  };
};

const submissionSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    adminReviewSubmission: action.data,
  };
};

const updateSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    adminReviewUpdate: action.data,
  };
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADMIN_QUESTION_LIST: {
      return commonState(state);
    }
    case Actions.ADMIN_QUESTION_LIST_SUCCESS: {
      return adminQuestionListSuccess(state, action);
    }
    case Actions.ADMIN_QUESTION_LIST_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.SUBMIT_REVIEW: {
      return commonState(state);
    }
    case Actions.SUBMIT_REVIEW_SUCCESS: {
      return submissionSuccess(state, action);
    }
    case Actions.SUBMIT_REVIEW_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.EMPLOYEE_LIST: {
      return commonState(state);
    }
    case Actions.EMPLOYEE_LIST_SUCCESS: {
      return employeeList(state, action);
    }
    case Actions.EMPLOYEE_LIST_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.EMPLOYEE_DETAILS: {
      return commonState(state);
    }
    case Actions.EMPLOYEE_DETAILS_SUCCESS: {
      return employeeDetails(state, action);
    }
    case Actions.EMPLOYEE_DETAILS_FAILURE: {
      return commonFailer(state, action);
    }
    case Actions.UPDATE_REVIEW: {
      return commonState(state);
    }
    case Actions.UPDATE_REVIEW_SUCCESS: {
      return updateSuccess(state, action);
    }
    case Actions.UPDATE_REVIEW_FAILURE: {
      return commonFailer(state, action);
    }
    default:
      return state;
  }
};

export const getEmployeeList = (state) => state?.ADMIN?.employeeList;
export const getEmployeeDetails = (state) => state?.ADMIN?.employeeDetails;
