import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { doGet, doPost } from '../../service';
import { QUESTION_LIST, SAVE_DRAFT, SUBMIT_ANSWER, UPDATE_ANSWER, YEAR_LIST } from '../action/employeeAction';

export function* questionList({ params, callback }) {
  try {
    const response = yield doGet(END_POINTS.EMPLOYEE_QUESTION_LIST, params)
    yield put(Actions.questionListSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback(error)
    yield put(Actions.questionListFailure(error))
  }
}

export function* yearList({ params, callback }) {
  try {
    const response = yield doGet(END_POINTS.YEAR_LIST, params)
    yield put(Actions.yearListSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback(error)
    yield put(Actions.yearListFailure(error))
  }
}

export function* reviewSubmission({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.EMPLOYEE_REVIEW_SUBMISSION, params)
    yield put(Actions.submitAnswersSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback(error)
    yield put(Actions.submitAnswersFailure(error))
  }
}

export function* updateReviewSubmission({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.EMPLOYEE_REVIEW_UPDATE, params)
    yield put(Actions.submitAnswersSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback(error)
    yield put(Actions.submitAnswersFailure(error))
  }
}

export function* saveDraft({ params, callback }) {
  try {
    const response = yield doPost(END_POINTS.SAVE_DRAFT, params)
    yield put(Actions.saveDraftSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback(error)
    yield put(Actions.saveDraftFailure(error))
  }
}

export default function* employeeWatcher() {
  yield all([
    takeLatest(QUESTION_LIST, questionList),
    takeLatest(YEAR_LIST, yearList),
    takeLatest(SUBMIT_ANSWER, reviewSubmission),
    takeLatest(UPDATE_ANSWER, updateReviewSubmission),
    takeLatest(SAVE_DRAFT, saveDraft),
  ]);
}
