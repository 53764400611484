import * as login from './loginAction'
import * as employee from './employeeAction'
import * as admin from './adminAction'


export const Actions = {
    ...login,
    ...employee,
    ...admin
}
export const doAction = (type, params = {}, callback) => ({ type, params, callback });
export const doResponseAction = (type, data = {}) => ({ type, data });