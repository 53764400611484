import { doAction, doResponseAction } from ".";

const ADMIN_QUESTION_LIST = 'ADMIN_QUESTION_LIST';
const ADMIN_QUESTION_LIST_SUCCESS = 'ADMIN_QUESTION_LIST_SUCCESS';
const ADMIN_QUESTION_LIST_FAILURE = 'ADMIN_QUESTION_LIST_FAILURE';

const EMPLOYEE_LIST = 'EMPLOYEE_LIST';
const EMPLOYEE_LIST_SUCCESS = 'EMPLOYEE_LIST_SUCCESS';
const EMPLOYEE_LIST_FAILURE = 'EMPLOYEE_LIST_FAILURE';

const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS';
const SUBMIT_REVIEW_FAILURE = 'SUBMIT_REVIEW_FAILURE';

const UPDATE_REVIEW = 'UPDATE_REVIEW';
const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
const UPDATE_REVIEW_FAILURE = 'UPDATE_REVIEW_FAILURE';


const EMPLOYEE_DETAILS = 'EMPLOYEE_DETAILS';
const EMPLOYEE_DETAILS_SUCCESS = 'EMPLOYEE_DETAILS_SUCCESS';
const EMPLOYEE_DETAILS_FAILURE = 'EMPLOYEE_DETAILS_FAILURE';

export {
    ADMIN_QUESTION_LIST,
    ADMIN_QUESTION_LIST_FAILURE,
    ADMIN_QUESTION_LIST_SUCCESS,

    EMPLOYEE_LIST,
    EMPLOYEE_LIST_FAILURE,
    EMPLOYEE_LIST_SUCCESS,

    EMPLOYEE_DETAILS,
    EMPLOYEE_DETAILS_FAILURE,
    EMPLOYEE_DETAILS_SUCCESS,

    SUBMIT_REVIEW,
    SUBMIT_REVIEW_FAILURE,
    SUBMIT_REVIEW_SUCCESS,
  
    UPDATE_REVIEW,
    UPDATE_REVIEW_FAILURE,
    UPDATE_REVIEW_SUCCESS,
}

// EMPLOYEE_LIST
export const employeeList = (params, callback) => doAction(EMPLOYEE_LIST, params, callback);
export const employeeListSuccess = (data) => doResponseAction(EMPLOYEE_LIST_SUCCESS, data);
export const employeeListFailure = (data) => doResponseAction(EMPLOYEE_LIST_FAILURE, data);

// ADMIN_QUESTION_LIST
export const adminQuestionList = (params, callback) => doAction(ADMIN_QUESTION_LIST, params, callback);
export const adminQuestionListSuccess = (data) => doResponseAction(ADMIN_QUESTION_LIST_SUCCESS, data);
export const adminQuestionListFailure = (data) => doResponseAction(ADMIN_QUESTION_LIST_FAILURE, data);

// EMPLOYEE_DETAILS
export const employeeDetails = (params, callback) => doAction(EMPLOYEE_DETAILS, params, callback);
export const employeeDetailsSuccess = (data) => doResponseAction(EMPLOYEE_DETAILS_SUCCESS, data);
export const employeeDetailsFailure = (data) => doResponseAction(EMPLOYEE_DETAILS_FAILURE, data);

// SUBMIT_REVIEW
export const submitReview = (params, callback) => doAction(SUBMIT_REVIEW, params, callback);
export const submitReviewSuccess = (data) => doResponseAction(SUBMIT_REVIEW_SUCCESS, data);
export const submitReviewFailure = (data) => doResponseAction(SUBMIT_REVIEW_FAILURE, data);

// UPDATE_REVIEW
export const updateReview = (params, callback) => doAction(UPDATE_REVIEW, params, callback);
export const updateReviewSuccess = (data) => doResponseAction(UPDATE_REVIEW_SUCCESS, data);
export const updateReviewFailure = (data) => doResponseAction(SUBMIT_REVIEW_FAILURE, data);


