import { toast } from "react-toastify";
import END_POINTS from "../service/EndPoint";

let socket;

const redirectToScreen = () => {
    // redirect('/notification')
    window.location.href = '/notification'
}
export const StartSocketConnect = (callback, socketId) => {
    socket = new WebSocket(`${END_POINTS?.SOCKET_IO_PATH}/ws/socket-server?user_id=${socketId}`);
    socket.addEventListener('open', (event) => {
        console.log('Socket Connected ::::::::::::::::::::', event)
    })
    socket.addEventListener('message', (event) => {
        let parseData = JSON.parse(event?.data)
        toast.success(parseData?.message, {
            position: toast.POSITION.TOP_RIGHT,
            onClick: () => redirectToScreen(),
        })
    });

}

export const stopSocketConnect = (socketId) => {
    socket = new WebSocket(`${END_POINTS?.SOCKET_IO_PATH}/${END_POINTS?.SOCKET_URL}?user_id=${socketId}`);
    socket.onclose = (event) => {
        console.log('Socket disconnected :::::::::::::::::::::::::::', event);
    };
};