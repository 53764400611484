import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux/action";
import { getLoginData } from "../redux/reducer/loginReducer";
import MESSAGE, { IMG } from "../constant/String";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const otpRef1 = useRef(null);
    const otpRef2 = useRef(null);
    const otpRef3 = useRef(null);
    const otpRef4 = useRef(null);

    const [formDetails, setFormDetails] = useState({ empId: "", otp: "" });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({ empId: "", otpValue: "" });
    const [stage, setStage] = useState(1);
    const [otp, setOtp] = useState({ otpValue1: "", otpValue2: "", otpValue3: "", otpValue4: "" })

    const handleEmployeeIdChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, empId: MESSAGE?.PLEASE_ENTER_THE_EMPLOYEE_ID });
        } else {
            setError({ ...error, empId: '' });
        }
        setFormDetails({ ...formDetails, empId: event.target.value });
    };

    const handleChange = (e, otpKey, nextRef) => {
        const { value } = e.target;
        if (value.length > 1) return;
        setOtp(prevOtp => ({ ...prevOtp, [otpKey]: value }));
        if (value) {
            nextRef?.current?.focus();
        }
        if (!value || value.length === 0) {
            setError(prevError => ({ ...prevError, [otpKey]: true }));
        } else {
            setError(prevError => ({ ...prevError, [otpKey]: false }));
        }
    };

    const OnSubmit = () => {
        const { empId } = formDetails
        const callback = (res) => {
            setLoading(false)
            if (res?.response_code !== 0) {
                toast.error(res?.response_message);
            } else {
                toast.success(res?.response_message)
                setStage(2)
            }
        }
        const otpCallback = (res) => {
            setLoading(false)
            if (res?.response_code !== 0) {
                toast.error(res?.response_message);
            } else {
                toast.success(res?.response_message);
                navigate("/resetpassword", { state: { value: 0, empId: formDetails?.empId } })
            }
        }
        if (stage === 1) {
            if (empId === "") {
                setError({ ...error, empId: MESSAGE?.PLEASE_ENTER_THE_EMPLOYEE_ID });
            } else {
                setError({ ...error, empId: '' });
                let params = {
                    empId,
                }
                setLoading(true)
                dispatch(Actions.forgetPasswordUser(params, callback));
            }
        } else {
            if (otp.otpValue1 === "" || otp.otpValue2 === "" || otp.otpValue3 === "" || otp.otpValue4 === "") {
                setError({ ...error, otpValue: MESSAGE?.PLEASE_ENTER_THE_OTP });
            } else {
                setError({ ...error, otpValue: '' });
                let params = {
                    empId: empId,
                    forgetOtp: `${otp.otpValue1}${otp.otpValue2}${otp.otpValue3}${otp.otpValue4}`
                }
                setLoading(true)
                dispatch(Actions.verifyOtpUser(params, otpCallback));
            }
        }

    };
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div style={{ backgroundColor: '#FFF1D8', height: '100%', display: 'flex', alignItems: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mx-auto">
                            <div className="d-flex flex-column">
                                <div className="bg-primary p-5 text-center">
                                    <img src={IMG.LOGO} className="w-50" />
                                    <h5 className="mb-0 mt-3 text-white">PMS Portal</h5>
                                </div>
                            </div>
                            <div className="bg-white p-4">
                                {
                                    stage && stage === 1 ?
                                        <>
                                            <h3>Forget Password</h3>
                                            <div className="mb-3 mt-4">
                                                <label htmlFor="name" className="form-label">Employee Id</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="EmpId"
                                                    placeholder="Enter employee id"
                                                    onChange={(e) => handleEmployeeIdChange(e)} />
                                                {error?.empId && <p className="text-danger mt-2">{error?.empId}</p>}
                                            </div>
                                        </> :
                                        <div className="mb-3">
                                            <h3>Please Enter your OTP</h3>
                                            <div className="mt-4 mb-3 d-flex justify-content-center">
                                                <input
                                                    type="number"
                                                    className="form-control otp_input p-2 text-center"
                                                    id="otp1"
                                                    value={otp.otpValue1}
                                                    onChange={(e) => handleChange(e, 'otpValue1', otpRef2)}
                                                    ref={otpRef1}
                                                />
                                                <input
                                                    type="number"
                                                    className="form-control otp_input p-2 text-center"
                                                    id="otp2"
                                                    value={otp.otpValue2}
                                                    onChange={(e) => handleChange(e, 'otpValue2', otpRef3)}
                                                    ref={otpRef2}
                                                />
                                                <input
                                                    type="number"
                                                    className="form-control otp_input p-2 text-center"
                                                    id="otp3"
                                                    value={otp.otpValue3}
                                                    onChange={(e) => handleChange(e, 'otpValue3', otpRef4)}
                                                    ref={otpRef3}
                                                />
                                                <input
                                                    type="number"
                                                    className="form-control otp_input p-2 text-center"
                                                    id="otp4"
                                                    value={otp.otpValue4}
                                                    onChange={(e) => handleChange(e, 'otpValue4', null)}
                                                    ref={otpRef4}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                {error.otpValue ?
                                                    <h5 style={{ color: 'red', fontSize: '16px', marginTop: '10px' }}>{error.otpValue}
                                                    </h5> : ''
                                                }
                                            </div>
                                        </div>
                                }
                                <button className="btn btn-primary w-100 border-0" type="button" onClick={() => OnSubmit()}>
                                    Submit
                                </button>
                                <div className="d-flex justify-content-center mb-3 mt-4" >
                                    <div style={{ borderBottom: '2px solid #D46A19' }}>
                                        <span onClick={() => window.history.go(-1)} style={{ cursor: 'pointer' }}>
                                            Back to Login
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgetPassword;