import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux/action";
import MESSAGE, { IMG } from "../constant/String";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AppLoader from "../component/AppLoader";
import { useParams } from "react-router-dom";
import { getLoginData } from "../redux/reducer/loginReducer";
import PasswordCheckPopover from "../component/PasswordCheckPopover";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector(getLoginData);
    const location = useLocation()
    const { value, empId } = location.state || {};
    const [showPopoverForNewPass, setShowPopoverForNewPass] = useState(false)
    const [showPopoverForConfirmPass, setShowPopoverForConfirmPass] = useState(false)

    const [formDetails, setFormDetails] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({
        newPassword: "",
        confirmPassword: "",
        samePassword: "",
    });
    const [showPassword, setShowPassword] = useState({
        newPassword: false,
        confirmPassword: false,
    });
    // const [userId, setUserId] = useState(null);

    const handleNewPassword = (event) => {
        if (event.target.value === "") {
            setError({ ...error, newPassword: MESSAGE?.PLEASE_ENTER_THE_NEW_PASSWORD });
        } else if (!validatePassword(event.target.value)) {
            setError({ ...error, newPassword: "Strong password is required" });
        } else {
            setError({ ...error, newPassword: "" });
        }
        setFormDetails({ ...formDetails, newPassword: event.target.value });
    };

    const handleConfirmPassword = (event) => {
        if (event.target.value === "") {
            setError({
                ...error,
                confirmPassword: MESSAGE?.PLEASE_ENTER_THE_CONFIRM_PASSWORD,
            });
        } else if (!validatePassword(event.target.value)) {
            setError({ ...error, confirmPassword: "Strong password is required" });
        } else {
            setError({ ...error, confirmPassword: "" });
        }
        setFormDetails({ ...formDetails, confirmPassword: event.target.value });
    };
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*.])[A-Za-z\d!@#$%^&*.]+$/;
        // Password validation rules can be added here
        return passwordRegex.test(password) && password.length >= 8;
    };
    const OnSubmit = () => {
        const { newPassword, confirmPassword } = formDetails;
        console.log(newPassword, confirmPassword)
        const callback = (response) => {
            setLoading(false);
            if (response?.response_code === 0) {
                toast.success(response?.response_message);
                navigate("/");
            } else {
                toast.error(response?.response_message);
            }
        };
        if (newPassword === "") {
            setError({
                newPassword: MESSAGE?.PLEASE_ENTER_THE_NEW_PASSWORD,
            });
        } else if (!validatePassword(newPassword)) {
            setError({ ...error, newPassword: "Strong password is required" })
        } else if (confirmPassword === "") {
            setError({
                ...error,
                confirmPassword: MESSAGE?.PLEASE_ENTER_THE_CONFIRM_PASSWORD,
                newPassword: "",
            });
        } else if (!validatePassword(confirmPassword)) {
            setError({ ...error, confirmPassword: "Strong password is required", newPassword: "", })
        } else if (!value && newPassword !== confirmPassword) {
            setError({
                newPassword: "",
                confirmPassword: "",
            });
            toast.warning(MESSAGE?.ENTERED_PASSWORDS_ARE_NOT_SAME)
        } else {
            setLoading(true);
            if (value) {
                let params = {
                    oldPassword: newPassword,
                    newPassword: confirmPassword
                }
                dispatch(Actions.changePasswordUser(params, callback));
            } else {
                let params = {
                    empId: empId,
                    password: confirmPassword
                }
                dispatch(Actions.resetPasswordUser(params, callback));
            }
        }
    };

    const togglePasswordVisibility = (toggleType) => {
        if (toggleType === 1) {
            setShowPassword({
                newPassword: !showPassword.newPassword,
                confirmPassword: showPassword.confirmPassword,
            });
        } else {
            setShowPassword({
                newPassword: showPassword.newPassword,
                confirmPassword: !showPassword.confirmPassword,
            });
        }
    };
    return (
        <React.Fragment>
            {isLoading && <AppLoader />}
            <div
                style={{
                    backgroundColor: "#FFF1D8",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mx-auto">
                            <div className="d-flex flex-column">
                                <div className="bg-primary p-5 text-center">
                                    <img src={IMG.LOGO} className="w-50" />
                                    <h5 className="mb-0 mt-3 text-white">PMS Portal</h5>
                                </div>
                            </div>
                            <div className="bg-white p-4">
                                <h3>{value ? "Change Password" : "Reset Password"}</h3>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">
                                        {value ? "Old Password" : "New Password"}
                                    </label>
                                    <div className="input-group mb-3">
                                        {showPopoverForNewPass && <PasswordCheckPopover value={formDetails?.newPassword} />}
                                        <input
                                            type={showPassword.newPassword ? "text" : "password"}
                                            value={formDetails?.newPassword}
                                            onChange={(e) => handleNewPassword(e)}
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter New password"
                                            onFocus={() => setShowPopoverForNewPass(true)}
                                            onBlur={() => setShowPopoverForNewPass(false)}
                                            autoComplete="off"
                                        />
                                        <button
                                            onClick={() => togglePasswordVisibility(1)}
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="password"
                                        >
                                            <i
                                                className={`${!showPassword.newPassword
                                                    ? "far fa-eye-slash"
                                                    : "far fa-eye"
                                                    }`}
                                            ></i>
                                        </button>
                                    </div>
                                    {error?.newPassword && (
                                        <p className="text-danger mt-2">{error?.newPassword}</p>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">
                                        {value ? "New Password" : "Confirm Password"}
                                    </label>
                                    <div className="input-group mb-3">
                                        {showPopoverForConfirmPass && <PasswordCheckPopover value={formDetails?.confirmPassword} />}
                                        <input
                                            type={showPassword.confirmPassword ? "text" : "password"}
                                            value={formDetails?.confirmPassword}
                                            onChange={(e) => handleConfirmPassword(e)}
                                            className="form-control"
                                            id="password"
                                            placeholder="Enter Confirm password"
                                            onFocus={() => setShowPopoverForConfirmPass(true)}
                                            onBlur={() => setShowPopoverForConfirmPass(false)}
                                            autoComplete="off"
                                        />
                                        <button
                                            onClick={() => togglePasswordVisibility(2)}
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="password"
                                        >
                                            <i
                                                className={`${!showPassword.confirmPassword
                                                    ? "far fa-eye-slash"
                                                    : "far fa-eye"
                                                    }`}
                                            ></i>
                                        </button>
                                    </div>
                                    {error?.confirmPassword && (
                                        <p className="text-danger mt-2">{error?.confirmPassword}</p>
                                    )}
                                    {error?.samePassword && (
                                        <p className="text-danger mt-2">{error?.samePassword}</p>
                                    )}
                                </div>
                                <button
                                    className="btn btn-primary mt-3 w-100 border-0"
                                    type="button"
                                    onClick={() => OnSubmit()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ChangePassword;
