import { Navigate, Outlet } from "react-router-dom"
import { store } from "../../redux/store";
import React from "react";

const Layout = () => {
    const tokendata = store?.getState()?.USER;
    const { token } = tokendata;
    return (
        <>
            {token ?
                <React.Fragment>
                    <Outlet />
                </React.Fragment>
                : <Navigate to='/' />}
        </>
    )
}

export default Layout