import { Outlet, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoutes from './utils/PrivateRouter';
import Login from './container/Login';
import { ToastContainer } from 'react-toastify';
import { getLoginData } from "../src/redux/reducer/loginReducer";
import { useSelector } from 'react-redux';
import { StartSocketConnect } from './socket/SocketConnection';
import React, { useEffect } from 'react';
import Employee from './container/Employee';
import { PAGE } from './constant/String';
import Admin from './container/Admin';
import ChangePassword from './container/ChangePassword';
import ForgetPassword from './container/ForgetPassword';
import EmployeeList from './container/EmployeeList';

function App() {
  const userData = useSelector(getLoginData);
  useEffect(() => {
    if (userData?.socketId) {
      StartSocketConnect("", userData?.socketId);
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path={PAGE.HOME} element={<Login />} />
        <Route path={PAGE.FORGET_PASSWORD} element={<ForgetPassword />} />
        <Route path={PAGE.RESET_PASSWORD} element={<ChangePassword />} />
        <Route path="/" element={<PrivateRoutes />}>
          <Route path={PAGE.EMPLOYEE} element={<Employee />} />
          <Route path={PAGE.ADMIN} element={<EmployeeList />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
