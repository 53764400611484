import Pagination from "react-js-pagination";
import MESSAGE from '../constant/String'
export const PaginationComponent = ({ onChangeHandle, pageNumber, pageCount }) => {
    return (
        <>
            <div className="d-flex justify-content-center">
                <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={MESSAGE?.ITEM_COUNT_PER_PAGE}
                    totalItemsCount={Math.ceil(pageCount / MESSAGE?.ITEM_COUNT_PER_PAGE) * MESSAGE?.ITEM_COUNT_PER_PAGE}
                    pageRangeDisplayed={3}
                    onChange={onChangeHandle}
                    activeLinkClass='active'
                    itemClass='page-item'
                    // hideNavigation
                    // hideFirstLastPages
                    linkClass="page-link"
                    prevPageText='prev'
                    nextPageText='next'
                />
            </div>
        </>
    )
}